import React from 'react';
import { ITableHeaderDef } from '@loophealth/loop-ui-web-library/dist/types/atoms/Table/types';
import moment from 'moment';
import { StyledUpdatedDateWrapper } from './styles';
import WithThemeProvider from '../../../../../theme/WithThemeProvider';
import { Column } from 'exceljs';

export const BackdatedMemberTableColumns: ITableHeaderDef<
  Record<string, unknown>
> = [
    {
        name: 'S. No.',
        key: 'sno',
        cell: (_rowValue, index) => `${index + 1}.`,
        width: 90
    },
    {
        name: 'Employee ID',
        key: 'employee_id',
        width: 120
    },
    {
        name: 'Name',
        key: 'name',
        width: 170
    },
    {
        name: 'Relationship',
        key: 'relationship_to_account_holders',
        width: 100
    },
    {
        name: 'Current Coverage Date',
        key: 'old_policy_start_date',
        cell: (value) =>
            moment(value as string, 'DD/MM/YYYY').format('DD/MMM/YYYY'),
        width: 140
    },
    {
        name: 'Updated Coverage Date',
        key: 'policy_start_date',
        cell: (value) => (
            <WithThemeProvider>
                <StyledUpdatedDateWrapper>
                    {moment(value as string, 'DD/MM/YYYY').format('DD/MMM/YYYY')}
                </StyledUpdatedDateWrapper>
            </WithThemeProvider>
        ),
        width: 160
    }
];

export const BACKDATED_MEMBER_SHEET_HEADERS: Partial<Column>[] = [
    {
        header: 'S. No',
        key: 'srNo',
        width: 8
    },
    {
        header: 'Employee ID',
        key: 'employee_id',
        width: 20
    },
    {
        header: 'Relationship to Account Holder',
        key: 'relationship_to_account_holders',
        width: 20
    },
    {
        header: 'Name',
        key: 'name',
        width: 25
    },
    {
        header: 'Current Coverage Start Date',
        key: 'old_policy_start_date',
        width: 20,
        style: { numFmt: 'dd/mmm/yyyy' }
    },
    {
        header: 'Updated Coverage Start Date',
        key: 'policy_start_date',
        width: 20,
        style: { numFmt: 'dd/mmm/yyyy' }
    },
    {
        header: 'Enrolment Due Date',
        key: 'enrolment_due_date',
        width: 20,
        style: { numFmt: 'dd/mmm/yyyy' }
    },
    {
        header: 'Slab ID',
        key: 'slabId',
        width: 10
    },
    {
        header: 'Mobile',
        key: 'mobile',
        width: 20
    },
    {
        header: 'Email Address',
        key: 'email_address',
        width: 25
    },
    {
        header: 'Date of Birth',
        key: 'date_of_birth',
        width: 20,
        style: { numFmt: 'dd/mmm/yyyy' }
    },
    {
        header: 'Gender',
        key: 'gender',
        width: 10
    },
    {
        header: 'CTC',
        key: 'ctc',
        width: 10
    },
    {
        header: 'Exception',
        key: 'isException',
        width: 15
    }
];
