import React, { useMemo, useState } from 'react';
import {
    StyledEndorsementStatus,
    StyledEndorsementStatusWrapper,
    StyledExceptionChip,
    StyledExceptionContainer,
    StyledNextStatus,
    StyledStatus
} from './styles';
import { getStatusLabel } from '../../../../../utils/constants/Endorsement/PossibleValue';
import ChangeEndorsementStatusModal from '../../../../containers/ChangeEndorsementStatusModal';
import { IEndorsementStatusProps } from './types';
import { LoopButton } from '../../../../atoms';
import { IEndorsementExceptionData, IStatus } from '../../../../../redux/slices/EndorsementSlice/types';
import EndorsementFdModal from './EndorsementFdModal';
import {
    isEndorsementExceptionsEnabled,
    isFreshdeskValidatorIntegrated,
    isValidatorBackdatingEnabled
} from '../../../../../utils/featureFlags';
import {
    checkIfBackdateApplicable,
    checkIfKYCNonVerified,
    checkIfMidtermNonVerified
} from '../../../../../utils/constants/Endorsement';
import { LoopButton as UILoopButton } from '@loophealth/loop-ui-web-library';
import EndorsementExceptionsModal from '../../../../containers/EndorsementExceptionsModal';
import { useSelector } from 'react-redux';
import { selectCurrentEndorsementExceptions } from '../../../../../redux/slices/EndorsementSlice/selectors';
import RefreshBackdatedMembersCard from '../../../../containers/RefreshBackdateMembersCard';

const EndorsementStateComponent: React.FunctionComponent<IEndorsementStatusProps> = ({
    endorsement,
    currentStatus,
    nextStatus,
    isCDBalanceSufficient,
    cdBalanceOverview,
    toDisableCTAForEndorsementState,
    updateEndorsementStatus,
    setEndorsementDetails,
    policyId
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const isEndoComplete = currentStatus == 'ENDO_COMPLETE';
    const [newState, setNewState] = useState<IStatus>(nextStatus);
    const [freshdeskModal, setFreshdeskModal] = useState(false);
    const [exceptionsModal, setExceptionsModal] = useState(false);
    const currentEndorsementExceptions: IEndorsementExceptionData[] = useSelector(selectCurrentEndorsementExceptions);

    const openStatusChangeModal = (nextStatus: IStatus) => {
        if (isFreshdeskValidatorIntegrated && nextStatus === 'IN_PROGRESS') {
            setFreshdeskModal(true);
        } else {
            setNewState(nextStatus);
            setIsVisible(true);
        }
    };

    const midTermVerified = useMemo(
        () => !endorsement?.memberData?.filter((member) => checkIfMidtermNonVerified(member, currentStatus)).length,
        [endorsement]
    );

    const kycDocumentsVerified = useMemo(
        () => !endorsement?.memberData?.filter((member) => checkIfKYCNonVerified(member, currentStatus)).length,
        [endorsement]
    );

    const backdateApplicableMembers = useMemo(
        () =>
            endorsement?.memberData && isValidatorBackdatingEnabled
                ? endorsement.memberData.filter((member) =>
                    checkIfBackdateApplicable(member, currentStatus)
                ).length
                : 0,
        [endorsement]
    );

    const isMoveToEnabled = useMemo(() => {
        return (
            (isCDBalanceSufficient || !toDisableCTAForEndorsementState) && midTermVerified &&
                kycDocumentsVerified && currentEndorsementExceptions.length === 0 && !backdateApplicableMembers
        );
    }, [
        currentEndorsementExceptions,
        isCDBalanceSufficient,
        toDisableCTAForEndorsementState,
        midTermVerified,
        kycDocumentsVerified
    ]);

    return (
        <>
            <ChangeEndorsementStatusModal
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                updateEndorsementStatus={updateEndorsementStatus}
                endorsementId={endorsement?.id}
                currentStatus={currentStatus}
                nextStatus={newState}
                policyId={policyId}
            />
            <EndorsementExceptionsModal
                supportTicketId={endorsement?.supportTicketId}
                isVisible={exceptionsModal}
                setIsVisible={setExceptionsModal}
                currentStatus={currentStatus}
            />
            {isFreshdeskValidatorIntegrated &&
                <EndorsementFdModal
                    visible={freshdeskModal}
                    setIsVisible={setFreshdeskModal}
                    endorsement={endorsement}
                    isCDBalanceSufficient={isCDBalanceSufficient}
                    cdAccountOverview={cdBalanceOverview}
                    nextStatus={nextStatus}
                    updateEndorsementStatus={updateEndorsementStatus}
                    setEndorsementDetails={setEndorsementDetails}
                />
            }
            <RefreshBackdatedMembersCard backdatedMembersCount={backdateApplicableMembers} />
            <StyledEndorsementStatusWrapper>
                <StyledEndorsementStatus>
                    <StyledStatus>
                            Current Endorsement Status:
                        <span>{getStatusLabel(currentStatus)}</span>
                    </StyledStatus>
                    <StyledNextStatus>
                        {
                            isEndorsementExceptionsEnabled && (
                                <UILoopButton
                                    variant="outline"
                                    backgroundColor="transparent"
                                    fontWeight="semiBold"
                                    onClick={() => setExceptionsModal(true)}
                                >
                                    {currentEndorsementExceptions.length > 0 ? 'Edit' : 'Add'} Exception
                                </UILoopButton>
                            )
                        }
                        {!isEndoComplete && (
                            <LoopButton
                                type={isMoveToEnabled ? 'filled' : 'disabled'}
                                onClick={() => openStatusChangeModal(nextStatus)}
                            >
                                <span>Move to {getStatusLabel(nextStatus)}</span>
                            </LoopButton>
                        )}
                        {currentStatus === 'SENT_TO_INSURER' && (
                            <LoopButton type="outline" onClick={() => openStatusChangeModal('ENDO_DECLINED')}>
                                    Move to Endo Declined
                            </LoopButton>
                        )}
                    </StyledNextStatus>
                </StyledEndorsementStatus>
                {
                    currentEndorsementExceptions.length > 0 && isEndorsementExceptionsEnabled ? (
                        <StyledExceptionContainer>
                            {
                                currentEndorsementExceptions.map((exception) => (
                                    <StyledExceptionChip key={exception.id}>
                                        {exception.name}
                                    </StyledExceptionChip>
                                ))
                            }
                        </StyledExceptionContainer>
                    ) : null
                }
            </StyledEndorsementStatusWrapper>
        </>
    );
};
export default EndorsementStateComponent;
