import React, { useEffect, useRef, useState } from 'react';
import { IRefreshBackdatedMembersCard } from './types';
import {
    StyledBackdatedTextContainer,
    StyledRefreshBackdatedMembersCard
} from './styles';
import { Alerts, Typography } from '@loophealth/loop-ui-web-library';
import { AnnounceIcon, CloudRefreshIcon } from '../../../assets/img';

const RefreshBackdatedMembersCard: React.FC<IRefreshBackdatedMembersCard> = ({
    backdatedMembersCount
}) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const defaultOffsetTop = useRef<number | null>(null);
    const [isElementSticked, setIsElementSticked] = useState(false);

    useEffect(() => {
        const appWrapper = document.getElementById('endo-details-wrapper');
        const handleScroll = () => {
            if (elementRef.current) {
                const offsetTop = elementRef.current.offsetTop;
                if (defaultOffsetTop.current === null) {
                    defaultOffsetTop.current = elementRef.current.offsetTop;
                }
                setIsElementSticked(offsetTop !== defaultOffsetTop.current);
            }
        };

        appWrapper?.addEventListener('scroll', handleScroll);
        return () => appWrapper?.removeEventListener('scroll', handleScroll);
    }, []);

    const handleRefreshMembers = () => {
        console.log('integration pending');
    };

    return backdatedMembersCount ? (
        <StyledRefreshBackdatedMembersCard
            ref={elementRef}
            $isSticked={isElementSticked}
        >
            <Alerts.StatusAlert
                iconSrc={AnnounceIcon}
                text={
                    <StyledBackdatedTextContainer>
                        <Typography variant="medium" weight="semiBold" color="planRed">
              Attention: Coverage Dates Outdated!
                        </Typography>
                        <Typography variant="medium">
                            {backdatedMembersCount} lives have coverage start dates that are
              more than 45 days old. Click “Refresh Member Data” to
              automatically back date by 40 days from today.
                        </Typography>
                    </StyledBackdatedTextContainer>
                }
                variant={isElementSticked ? 'inline' : 'over'}
                buttonText="Refresh Member Data"
                onButtonClick={handleRefreshMembers}
                buttonProps={{
                    variant: 'filled',
                    iconOrder: 'left',
                    iconSrc: CloudRefreshIcon
                }}
            />
        </StyledRefreshBackdatedMembersCard>
    ) : null;
};

export default RefreshBackdatedMembersCard;
