import ExcelJs, { Worksheet } from 'exceljs';
import { excelDownloadUsingBlob } from '../../../../../utils/common';
import { BACKDATED_MEMBER_SHEET_HEADERS } from './constants';
import moment from 'moment';

const generateBackdatedDataSheet = async (
    backdatedData: Record<string, unknown>[],
    fileName: string
): Promise<void> => {
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Backdated Add Members');
    worksheet.columns = structuredClone(BACKDATED_MEMBER_SHEET_HEADERS);
    worksheet.addRows(
        backdatedData.map((memberData, index) => ({
            ...memberData,
            enrolment_due_date: moment(memberData.enrolment_due_date as string, 'DD/MM/YYYY').toDate(),
            date_of_birth: moment(memberData.date_of_birth as string, 'DD/MM/YYYY').toDate(),
            policy_start_date: moment(memberData.policy_start_date as string, 'DD/MM/YYYY').toDate(),
            old_policy_start_date: moment(memberData.old_policy_start_date as string, 'DD/MM/YYYY').toDate(),
            srNo: index + 1
        }))
    );
    formatWorksheet(worksheet);

    const buffer = await workbook.xlsx.writeBuffer();
    const file = new File([buffer], fileName, {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    excelDownloadUsingBlob(file, fileName);
};

const formatWorksheet = (worksheet: Worksheet): void => {
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.font = {
            name: rowNumber === 1? 'Arial' : 'Calibri',
            family: 2,
            size: rowNumber === 1? 12 : 11,
            bold: rowNumber === 1,
            color: { argb: '000000' }
        };
        row.alignment = {
            horizontal: 'center',
            vertical: 'middle',
            wrapText: true
        };
        row.eachCell((cell) => {
            row.height = rowNumber === 1
                ? 50
                : Math.max(20, row.height, cell.value?.toString().trim().length || 0);
            cell.border = {
                left: { style: 'thin' },
                right: { style: 'thin' },
                top: rowNumber === 1 ? { style: 'thin' } : undefined,
                bottom: rowNumber === 1 ? { style: 'thin' } : undefined
            };
            if (rowNumber === 1) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'E7E6E6'
                    }
                };
            }
        });
    });
};

export default generateBackdatedDataSheet;
