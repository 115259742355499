/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { compress, decompress, trimUndefinedRecursively } from 'compress-json';
import { IBulkUploadData } from '../components/containers/BulkUploader/types';
import { IIncorrectPremiumEstimates } from '../components/containers/ValidationSummaryTable/types';
import { getUserData as getUserDataDB } from '../components/pages/LoginContainer/adapters';
import { IMemberDataToUpload, ValidatorResult } from '../components/pages/SPAContainer/pages/ValidationSummary/types';
import { ICDAccount, ICDLedger } from '../redux/slices/CDAccountSlice/types';
import { IcrListItem } from '../redux/slices/ChangeRequestSlice/types';
import { ICompanyData } from '../redux/slices/CompanySlice/types';
import {
    IPolicy,
    IPolicyPlanCovid,
    IPolicyPlanGMC,
    IPolicyPlanGPA,
    IPolicyPlanGTL
} from '../redux/slices/PolicySlice/types';
import { IUserPoliciesData } from '../redux/slices/UserPoliciesSlice/types';
import {
    isDeprecateDBCallsEnabled,
    isIamAPIEnabled,
    isIssuanceFlowEnabled,
    isNewBulkRegisterEnabled
} from '../utils/featureFlags';
import { del, FirebaseStorage, get, patch, post, put } from './provider';
import {
    IEnrolmentConfigSuccessResponse,
    IEnrolmentReport,
    IEnrolmentSuccessResponse,
    INomineeReport,
    IPolicySuccessResponse,
    ISuccessResponse,
    ISuccessResponseWithStatusCode,
    IUserData,
    IUserGroup
} from './types';
import { IHrMappingSheetsResponse } from '../components/pages/SPAContainer/pages/HrAddition/types';

const ENDPOINTS = {
    company: `/company`,
    user: `/user`,
    scripts: `/scripts`,
    policy: (policyId: string): string => `/policyPlan/${policyId}`,
    bulk: `/bulk`,
    insurer: (insurerName: string): string => `/insurerMasterData/${insurerName}`,
    userPolicies: `/userPolicies`,
    createPolicy: `/policyPlan`,
    updatePolicy: (policyId: string): string => `/policyPlan/${policyId}`,
    changeRequest: `/userPolicyCR`,
    companyWiseChangeRequestStats: `/userPolicyCR/fetchCompanyWiseAggregatedCR`,
    bulkMoveCRToNextState: `/bulk/moveCRToNextState`,
    fetchPolicyList: (status: string, companyId: string): string => `/policyPlan/${status}/${companyId}`,
    premiumRater: `/premiumRater`,
    cdAccountByField: (searchField: string, searchFieldValue: string): string =>
        `/cdAccount/search?field=${searchField}&value=${searchFieldValue}`,
    cdAccount: `/cdAccount`,
    cdAccountById: (id: string): string => `/cdAccount/${id}`,
    cdLedger: (id: string): string => `/cdLedger/${id}`,
    addBalanceAdjustment: `/cdAccount/addBalanceAdjustment`,
    pendingEndorsements: '/endorsement/pending',
    completedEndorsements: '/endorsement/completed',
    endorsementById: (id: string): string => `/endorsement/${id}?includeKycDocuments=true`,
    fetchAllEndorsementExceptions: '/endoExceptions/fetchAllExceptions',
    updateEndorsementExceptionsById: (id: string): string => `/endorsement/updateEndorsementExceptionIds/${id}`,
    cdBalanceOverviewById: (id: string): string => `/endorsement/fetchPremiumsAndCdBalanceDetails/${id}`,
    updateEndorsementStatus: (id: string): string => `/endorsement/updateEndorsementStatus/${id}`,
    renameEndorsementCycle: (id: string): string => `/endorsement/renameEndorsement/${id}`,
    reassignEndorsementMembers: (id: string): string => `/endorsement/reassignMembers/${id}`,
    fetchReassignMembersAllowedStatesAPI: (): string => `/endorsement/reassignMembers/allowedStates`,
    addEndorsementNote: (id: string): string => `/endorsement/addNote/${id}`,
    updateSupportTicketId: (id: string): string => `/endorsement/updateSupportTicketId/${id}`,
    getECard: (userId: string, policyId: string): string => `/userPolicies/generateECard/${userId}/${policyId}`,
    cdLedgerExport: (id: string) => `/cdLedger/cdLedgerExport/${id}`,
    reports: '/reports',
    assignNickName: '/policyPlan/assignNickName',
    deleteEndorsement: (id: string) => `/endorsement/${id}`,
    fetchActivePoliciesByCompanyId: (id: string) => `/policyPlan/fetchPolicies/${id}`,
    getRolesPermissions: `/roles/getRolePermissions`,
    getAppPermissionID: (value: string) => `/roles/getAppPermissionId/${value}`,
    getAppPermissionIDfromIAM: (value: string) => `/iam/${value}`,
    getUserData: (key: string, value: string, patientType?: string) =>
        `/user/fetchUsers?key=${key}&value=${value}&patientType=${patientType}`,
    updateUserData: (userId: string) => `/user/${userId}`,
    fetchHRMSDataByOperationType: `/scripts/fetchHRMSDataByOperationType`,
    downloadMemberData: (insurer: string, endoId?: string) => `/endorsement/memberData/${insurer}/${endoId}`,
    identifyKyc: '/midTermAdditions/identifyKYCRequirement',
    identifyMidTerms: '/midTermAdditions/identifyMidtermAdditions',
    validateMidTerms: '/midTermAdditions/validateMidtermAdditions',
    unifiedUpload: '/registration/genome/unifiedUpload',
    offboard: '/registration/genome/offboard',
    addMembers: '/registration/genome/add',
    editMembers: '/registration/genome/edit',
    migrateDataOfDupUsers: '/recon/migrateDataOfDuplicateUsers',
    recalculatePremiums: (id: string): string => `/endorsement/recalculatePremiums/${id}`,
    uploadCDStatement: (cdAccountId: string) => `/cdAccount/${cdAccountId}/cdAccountStatement`,
    downloadCDStatement: (cdAccountId: string) => `/cdAccount/${cdAccountId}/cdAccountStatement`,
    downloadPremiumRaterXlsx: (policyId: string) => `/premiumRater/downloadPremiumRater/${policyId}`,
    deleteCDStatement: (cdAccountId: string, fileIdentifier: string) =>
        `/cdAccount/${cdAccountId}/cdAccountStatement/${fileIdentifier}`,
    deleteCDAccount: (cdAccountId: string) => `/cdAccount/${cdAccountId}?sendEmail=true`,
    deletePolicyDocument: (policyId: string, fileIdentifier: string) =>
        `/policyPlan/${policyId}/policyDocument/${fileIdentifier}`,
    downloadPolicyDocument: (policyId: string, fileIdentifier: string) =>
        `/policyPlan/${policyId}/policyDocument/${fileIdentifier}`,
    uploadPolicyDocument: (policyId: string) => `/policyPlan/${policyId}/policyDocument`,
    hrMappings: '/scripts/hrd/getHRMapping',
    incorrectPremiumEsimates: '/premiumRater/estimate',
    getCdAccountByPolicyId: (policyId: string) => `/cdAccount/getCdAccountByPolicyId/${policyId}`,
    addFreshdeskComment: (ticketId: string): string => `/freshdesk/sendPvtNote/${ticketId}`,
    addFreshdeskCommentWithAttachment: (ticketId: string): string => `/freshdesk/sendPvtNoteWithAttachment/${ticketId}`,
    getMidtermDocument: (fileURL: string): string => `/midTermAndKycDocuments/processDocument/${fileURL}`,
    updateDocumentVerificationStatus: (
        userId: string,
        endoId: string,
        verification: boolean,
        isMidterm: boolean
    ): string =>
        `/endorsement/updateDocumentVerificationStatus/${userId}/${endoId}/${verification}${isMidterm ?
            '?isMidtermMember=true' : ''}`,
    modifyCSDForMidterms: `/midTermAdditions/modifyCSDForMidterms`,
    enrolmentConfig: `/enrolmentConfig`,
    fetchEnrolmentConfigsByCompany: (companyId: string): string => `/enrolmentConfig/company/${companyId}`,
    fetchUserGroup: '/user-group',
    fetchEnrolmentConfigById: (id: string): string => `/enrolmentConfig/${id}`,
    extendEnrolmentConfig: (id: string) => `/enrolmentConfig/${id}/extend`,
    sendConfirmationEmail: (id: string) => `/enrolmentConfig/${id}/complete`,
    hrdGsheetOperations: `/sheets/hrdOperations`
};

const isUserAuthorised = async (roles: string[], value: string): Promise<boolean> => {
    const permissionsPromise: any = post(ENDPOINTS.getRolesPermissions, { roles });
    let appPermissionIdPromise: any;
    if (isIamAPIEnabled) {
        appPermissionIdPromise = get(ENDPOINTS.getAppPermissionIDfromIAM(value));
    } else {
        appPermissionIdPromise = get(ENDPOINTS.getAppPermissionID(value));
    }

    const [permissions, appPermissionId] = await Promise.all([permissionsPromise, appPermissionIdPromise]);
    if (!permissions.length || !appPermissionId.trim()) return false;
    if (permissions?.includes(appPermissionId)) return true;
    return false;
};

const getUserData = async (key: string, value: string, patientType?: string): Promise<any> => {
    if (isDeprecateDBCallsEnabled) {
        const result = await get(ENDPOINTS.getUserData(key, value, patientType));
        return result;
    } else {
        const result = await getUserDataDB(key, value);
        return result;
    }
};

const updateUserData = async (userId: string, data: Partial<IUserData>): Promise<any> =>
    await put(ENDPOINTS.updateUserData(userId), data);

const getScriptsEndpoint = (): string => {
    return ENDPOINTS.scripts;
};
const fetchAllCompaniesAPI = async (): Promise<ICompanyData[]> => {
    const companies = await get(ENDPOINTS.company);
    return (companies.data as any).companies as unknown as ICompanyData[];
};

const fetchUsersByCompanyAPI = async (companyId: string): Promise<any> => {
    const result = await get(ENDPOINTS.user + '?' + `employer=${companyId.trim()}` + '&compressed=true');
    return (result.data as any).users;
};
const fetchUsersByPolicyAPI = async (companyId: string, policyId: string): Promise<any> => {
    const result = await get(ENDPOINTS.user + '/fetchMembersByPolicy' + `/${companyId.trim()}` + `/${policyId}`);
    return (result.data as any).users;
};

const fetchUsersByParamAPI = async (
    attribute: 'email' | 'mobile' | 'employeeId',
    value: string
): Promise<IUserData[]> => {
    const result = await get(ENDPOINTS.user + '?' + `${attribute}=${value.trim()}` + '&compressed=true');
    return (result.data as any)?.users?.map((u: any) => fetchUserByIdDateConversion(u));
};
export const fetchUserByIdDateConversion = (data: Record<string, any>): IUserData => {
    const dateFields = ['chatDurationLimit', 'visitDurationLimit', 'dob', 'createdAt', 'updatedAt', 'joiningDate'];
    Object.keys(data).map((k) => {
        if (dateFields.includes(k)) {
            data[k] = data[k]?._seconds ? new Date(data[k]?._seconds * 1000) : new Date(data[k] * 1000);
        }
    });
    return data as unknown as IUserData;
};

const fetchPolicyAPI = async (policyId: string): Promise<any> => {
    const policy: any = await get(ENDPOINTS.policy(policyId));
    return policy as IPolicyPlanGPA[] | IPolicyPlanCovid[] | IPolicyPlanGMC[] | IPolicyPlanGTL[];
};

const deleteUserAPI = async (userId: string): Promise<any> => {
    try {
        const res: any = await del(ENDPOINTS.user + '/' + userId);
        if (res.name === 'BadRequest') {
            throw new Error(res.message);
        } else {
            return res;
        }
    } catch (error) {
        return error;
    }
};

const updateEmployeeIdAPI = async (userId: string, employeeId: string, employer: string): Promise<any> => {
    try {
        const res: any = await put(ENDPOINTS.scripts + '/updateEmployeeId/' + userId, { employeeId, employer });
        if (res.name === 'BadRequest') {
            throw new Error(res.message);
        } else {
            return res;
        }
    } catch (error) {
        throw error;
    }
};

const fetchUserPoliciesByUserAPI = async (userId: string): Promise<IUserPoliciesData[]> => {
    const result: any = await get(ENDPOINTS.userPolicies + `/${userId}`);
    return result.data.userPolicies.map((uPol: any) => ({ ...uPol, userId } as IUserPoliciesData));
};

const uploadUHIDsAPI = async (
    policyId: string,
    membersList: Record<string, unknown>[]
): Promise<Record<string, unknown>[]> => {
    const result = await post(ENDPOINTS.bulk + `/uploadUHIDs/${policyId}`, {
        memberData: membersList
    });
    return (result.data as any).failure as Record<string, unknown>[];
};

const bulkAddMembersAPI = async (
    policyId: string,
    membersList: IBulkUploadData[],
    companyId: string,
    isEnrolmentPortalEnabled: boolean,
    isEndorsementEnabled: boolean
): Promise<Record<string, unknown>[]> => {
    if (isNewBulkRegisterEnabled) {
        trimUndefinedRecursively(membersList);
        const compressed = compress(membersList);
        const response = await post(ENDPOINTS.addMembers, {
            companyId,
            policyId,
            membersList: compressed,
            enableEnrolmentPortal: isEnrolmentPortalEnabled,
            compression: true,
            enableEndorsements: isEndorsementEnabled
        });
        return decompress((response.data as any).data);
    }
    const result = await post(ENDPOINTS.addMembers, {
        companyId,
        policyId,
        membersList,
        enableEnrolmentPortal: isEnrolmentPortalEnabled ? 'true' : 'false'
    });
    return (result.data as any).data as Record<string, unknown>[];
};
const bulkEditMembersAPI = async (
    policyId: string,
    membersList: IBulkUploadData[],
    companyId: string,
    isEnrolmentPortalEnabled: boolean,
    isEndorsementEnabled: boolean
): Promise<Record<string, unknown>[]> => {
    if (isNewBulkRegisterEnabled) {
        trimUndefinedRecursively(membersList);
        const compressed = compress(membersList);
        const response = await post(ENDPOINTS.editMembers, {
            companyId,
            policyId,
            membersList: compressed,
            enableEnrolmentPortal: isEnrolmentPortalEnabled,
            compression: true,
            enableEndorsements: isEndorsementEnabled
        });
        return decompress((response.data as any).data);
    }
    const result = await post(ENDPOINTS.editMembers, {
        companyId,
        policyId,
        membersList,
        enableEnrolmentPortal: isEnrolmentPortalEnabled ? 'true' : 'false'
    });
    return (result.data as any).data as Record<string, unknown>[];
};

const bulkOffboardUsersAPI = async (
    companyId: string,
    membersList: Record<string, unknown>[],
    enableEndorsements: boolean
): Promise<any> => {
    try {
        let endorsementsFlag = true;
        if (isIssuanceFlowEnabled) {
            endorsementsFlag = enableEndorsements;
        }
        const result = await post(ENDPOINTS.offboard, {
            companyId,
            membersList: membersList.map((m) => ({ ...m, userId: (m.userId || '').toString() })),
            enableEndorsements: endorsementsFlag
        });
        const modifiedResult = {
            data: {
                success: (result.data as { validMembers?: any[] })?.validMembers || [],
                failure: (result.data as { invalidMembers?: any[] })?.invalidMembers || []
            }
        };

        return modifiedResult.data as any;
    } catch (e) {
        throw e;
    }
};
const migrateDataOfDuplicateUsers = async (membersList: Record<string, unknown>[]): Promise<any> => {
    try {
        const result = await post(ENDPOINTS.migrateDataOfDupUsers, {
            data: membersList
        });
        return result.data as any;
    } catch (e) {
        throw e;
    }
};
const generateEnrolmentReportAPI = async (
    companyId: string,
    fromDate: Date,
    toDate: Date
): Promise<{
    enrolmentReport: IEnrolmentReport[];
    nomineeReport: INomineeReport[];
}> => {
    const response = await post(ENDPOINTS.reports + `/generateEnrolmentReport/${companyId}/${fromDate}/${toDate}`, {});
    return {
        enrolmentReport: (response.enrolmentReport as Record<string, unknown>[]).map(
            (v) =>
            ({
                companyId: v.companyId,
                companyName: v.companyName,
                employeeId: v.employeeId,
                userId: v.userId,
                name: v.name,
                relationship: v.relationship,
                email: v.email,
                mobile: v.mobile,
                dob: (v.dob && new Date(v.dob as string)) || undefined,
                gender: v.gender,
                customFields: v.customFields,
                policyId: v.policyId,
                policyType: v.policyType,
                isCommonPool: v.isCommonPool,
                isTopUpPolicy: v.isTopUpPolicy,
                isParentalPolicy: v.isParentalPolicy,
                sumInsured: v.sumInsured,
                annualPremium: v.annualPremium,
                enrolmentStatus: v.enrolmentStatus,
                enrolmentDueDate: (v.enrolmentDueDate && new Date(v.enrolmentDueDate as string)) || undefined,
                createdAt: (v.createdAt && new Date(v.createdAt as string)) || undefined,
                updatedAt: (v.updatedAt && new Date(v.updatedAt as string)) || undefined
            } as IEnrolmentReport)
        ),
        nomineeReport: (response.nomineeReport as Record<string, unknown>[]).map(
            (v) =>
            ({
                companyName: v.companyName,
                employeeId: v.employeeId,
                employeeUserId: v.employeeUserId,
                name: v.name,
                nomineeRelationship: v.nomineeRelationship,
                dateOfBirth: (v.dateOfBirth && new Date(v.dateOfBirth as string)) || undefined,
                gender: v.gender,
                nomineePercentageSI: v.nomineePercentageSI,
                policyRefId: v.policyRefId,
                policyType: v.policyType,
                sumInsured: v.sumInsured,
                enrolmentStatus: v.enrolmentStatus,
                enrolmentDueDate: (v.enrolmentDueDate && new Date(v.enrolmentDueDate as string)) || undefined,
                createdAt: (v.createdAt && new Date(v.createdAt as string)) || undefined,
                updatedAt: (v.updatedAt && new Date(v.updatedAt as string)) || undefined
            } as INomineeReport)
        )
    };
};

const uploadCompanyLogoAPI = async (file: Record<string, unknown>): Promise<void> => {
    const filePath = `/company/${(file as Record<'name', string>).name}`;
    await FirebaseStorage.ref(filePath).put(file as unknown as Blob);
};
const createCompanyAPI = async (company: Record<string, unknown>): Promise<ICompanyData> => {
    const result = await post(ENDPOINTS.company, {
        name: company.name,
        companyLogo: company.companyLogo,
        gstNumber: company.gstNumber,
        panNumber: company.panNumber,
        salesforceId: company.salesforceId,
        nickname: company.nickname,
        regdAddress: company.regdAddress,
        spocName: company.spocName,
        spocEmail: company.spocEmail,
        spocPhoneNumber: company.spocPhoneNumber
    });
    return (result.data as any).companyDetails as unknown as ICompanyData;
};

const fetchInsurerDetailsAPI = async (insurerName: string): Promise<string> => {
    const insurerData: any = await get(ENDPOINTS.insurer(insurerName));
    return (insurerData?.data?.insurerMasterData?.logo || '') as string;
};

const createPolicyAPI = async (policyData: IPolicy): Promise<string> => {
    const response: any = await post(ENDPOINTS.createPolicy, { ...policyData });

    return response;
};

const updatePolicyAPI = async (policyId: string, policyData: IPolicy): Promise<string> => {
    try {
        const response: any = await put(ENDPOINTS.updatePolicy(policyId), { ...policyData });
        return response;
    } catch (e) {
        throw e;
    }
};
export const convertDateTypeFieldsForUser = (data: Record<string, any>): IUserData => {
    const dateFields = ['chatDurationLimit', 'visitDurationLimit', 'dob', 'createdAt', 'updatedAt', 'joiningDate'];
    Object.keys(data).map((k) => {
        if (dateFields.includes(k)) {
            data[k] = data[k]?._seconds ? data[k]?._seconds : data[k];
        }
    });
    return data as unknown as IUserData;
};

const fetchChangeRequestsAPI = async (filterChangeRequestOptions: Record<string, any>): Promise<unknown> => {
    try {
        const params = Object.entries(filterChangeRequestOptions)
            .map(([k, v]) =>
                Array.isArray(v)
                    ? `${k}=${v.join(',')}`
                    : typeof v === 'string' || typeof v === 'number'
                        ? `${k}=${v}`
                        : ``
            )
            .filter(Boolean)
            .join('&');
        const url = params !== '' ? ENDPOINTS.changeRequest + '?' + params : ENDPOINTS.changeRequest;
        const response: any = await get(url);
        if (!response.data) {
            throw new Error(response.message ?? 'ChangeRequestsAPI failed');
        }
        return response.data;
    } catch (e) {
        throw e;
    }
};

const fetchCompanyWiseChangeRequestStats = async (): Promise<unknown> => {
    try {
        const response: any = await get(ENDPOINTS.companyWiseChangeRequestStats);
        if (!response.data) {
            throw new Error(response.message ?? 'companyWiseChangeRequestStatsAPI failed');
        }
        return response.data;
    } catch (e) {
        throw e;
    }
};

const postBulkMoveCRToNextState = async (crList: IcrListItem[]): Promise<unknown> => {
    try {
        const response: any = await post(ENDPOINTS.bulkMoveCRToNextState, { crList });
        return response;
    } catch (e) {
        throw e;
    }
};

const fetchActivePoliciesList = async (companyId: string): Promise<IPolicySuccessResponse> => {
    try {
        const response = (await get(ENDPOINTS.fetchPolicyList('active', companyId))) as IPolicySuccessResponse;
        return response;
    } catch (e) {
        throw e;
    }
};

const fetchEnrolmentConfigByCompanyId = async (companyId: string): Promise<IEnrolmentSuccessResponse> => {
    try {
        const response = (await get(ENDPOINTS.fetchEnrolmentConfigsByCompany(companyId))) as IEnrolmentSuccessResponse;
        return response;
    } catch (e) {
        throw e;
    }
};

const fetchEnrolmentConfigById = async (id: string): Promise<IEnrolmentConfigSuccessResponse> => {
    try {
        const response = (await get(ENDPOINTS.fetchEnrolmentConfigById(id))) as IEnrolmentConfigSuccessResponse;
        return response;
    } catch (e) {
        throw e;
    }
};
const extendEnrolmentConfigAPI = async (id: string, date: Date): Promise<ISuccessResponseWithStatusCode> => {
    try {
        const response = (await patch(ENDPOINTS.extendEnrolmentConfig(id), {
            actionType: 'extend',
            enrolmentEndDate: date.toISOString()
        })) as unknown as ISuccessResponseWithStatusCode;
        return response;
    } catch (e) {
        throw e;
    }
};

const sendConfirmationEmailAPI = async (id: string): Promise<ISuccessResponseWithStatusCode> => {
    try {
        const response = (await patch(
            ENDPOINTS.sendConfirmationEmail(id)
        )) as unknown as ISuccessResponseWithStatusCode;
        return response;
    } catch (error) {
        throw error;
    }
};
const fetchExpiredPoliciesList = async (companyId: string): Promise<IPolicySuccessResponse> => {
    try {
        const response = (await get(ENDPOINTS.fetchPolicyList('expired', companyId))) as IPolicySuccessResponse;
        return response;
    } catch (e) {
        throw e;
    }
};
const uploadRaterAPI = async (rater: Record<string, unknown>): Promise<Record<string, unknown>> => {
    try {
        return await post(ENDPOINTS.premiumRater + `/rater/upload`, {
            ...rater
        });
    } catch (e) {
        throw e;
    }
};
const getEndPointForPremiumCalculation = (type: 'ADDITIONS' | 'EDITIONS' | 'DELETIONS') => {
    switch (type) {
        case 'ADDITIONS':
            return `/calculate/additonsPremium`;
        case 'EDITIONS':
            return '/calculate/editionsPremium';
        case 'DELETIONS':
            return `/calculate/deletionsPremium`;
        default:
            return `/calculate/additonsPremium`;
    }
};

const fetchCDAccounts = async (): Promise<ISuccessResponse> => {
    try {
        const response = (await get(ENDPOINTS.cdAccount)) as ISuccessResponse;
        return response;
    } catch (e) {
        throw e;
    }
};
const getRaterAPI = async (policyId: string): Promise<Record<string, unknown>> => {
    try {
        return (await get(ENDPOINTS.premiumRater + `/rater/download/${policyId}`)) as Record<string, unknown>;
    } catch (e) {
        throw e;
    }
};
const downloadRaterXlsx = async (policyId: string): Promise<ISuccessResponse | Response> => {
    try {
        const response: any = await get(ENDPOINTS.downloadPremiumRaterXlsx(policyId));
        return response;
    } catch (e) {
        throw e;
    }
};

const fetchCDAccountsByField = async (searchField: string, searchFieldValue: string): Promise<ISuccessResponse> => {
    try {
        const response = (await get(ENDPOINTS.cdAccountByField(searchField, searchFieldValue))) as ISuccessResponse;
        return response;
    } catch (e) {
        throw e;
    }
};

const fetchCDLedgerEntriesByID = async (id: string): Promise<ISuccessResponse> => {
    try {
        const response = (await get(ENDPOINTS.cdLedger(id))) as IPolicySuccessResponse;
        return response;
    } catch (e) {
        throw e;
    }
};

const addCdBalanceAdjustment = async (cdLedger: Partial<ICDLedger>): Promise<ISuccessResponse> => {
    try {
        const response = (await post(ENDPOINTS.addBalanceAdjustment, { ...cdLedger })) as unknown as ISuccessResponse;
        return response;
    } catch (e) {
        throw e;
    }
};

const createCdAccount = async (cdAccount: Partial<ICDAccount>): Promise<ISuccessResponse> => {
    try {
        const response = (await post(ENDPOINTS.cdAccount, { ...cdAccount })) as unknown as ISuccessResponse;
        return response;
    } catch (e) {
        throw e;
    }
};

const updateCdAccount = async (cdAccount: Partial<ICDAccount>): Promise<ISuccessResponse> => {
    try {
        const response = (await put(ENDPOINTS.cdAccountById(cdAccount.id as string), {
            ...cdAccount
        })) as unknown as ISuccessResponse;
        return response;
    } catch (e) {
        throw e;
    }
};

// Test code , this will be discarded
const freshDeskTicketCreation = async (): Promise<any> => {
    try {
        const formData = new FormData();
        formData.append('description', 'this is a sample ticket');
        formData.append('subject', 'Ticket Title');
        formData.append('status', '2');
        formData.append('priority', '2');
        formData.append('email', 'example@example.com');
        const result = await fetch('https://pradyumloop.freshdesk.com/api/v2/tickets/', {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Basic ${Buffer.from('9l4SkFhck3AjiCpWPqK' + ':' + '').toString('base64')}`
            }
        });
        return result;
    } catch (e) {
        throw e;
    }
};

const fetchPendingEndorsements = async (): Promise<ISuccessResponse> => {
    try {
        const response = (await get(ENDPOINTS.pendingEndorsements)) as ISuccessResponse;
        return response;
    } catch (e) {
        throw e;
    }
};

const fetchCompletedEndorsements = async (): Promise<ISuccessResponse> => {
    try {
        const response = (await get(ENDPOINTS.completedEndorsements)) as ISuccessResponse;
        return response;
    } catch (e) {
        throw e;
    }
};

const fetchAllEndorsementExceptionsAPI = async (): Promise<ISuccessResponse> => {
    try {
        const response = (await get(ENDPOINTS.fetchAllEndorsementExceptions)) as ISuccessResponse;
        return response;
    } catch (e) {
        throw e;
    }
};

const fetchEndorsementByID = async (id: string): Promise<ISuccessResponse> => {
    try {
        const response = (await get(ENDPOINTS.endorsementById(id))) as ISuccessResponse;
        return response;
    } catch (e) {
        throw e;
    }
};

const updateEndorsementExceptionsByIdAPI = async (id: string, exceptionIds: string[]): Promise<ISuccessResponse> => {
    try {
        const response: any = await put(ENDPOINTS.updateEndorsementExceptionsById(id), {
            exceptionIds
        });
        return response;
    } catch (e) {
        throw e;
    }
};

const fetchCdBalanceOverviewById = async (id: string): Promise<ISuccessResponse> => {
    try {
        const response = (await get(ENDPOINTS.cdBalanceOverviewById(id))) as ISuccessResponse;
        return response;
    } catch (e) {
        throw e;
    }
};

const updateEndorsementStatusAPI = async (
    endorsementId: string,
    requestData: Record<string, unknown>
): Promise<ISuccessResponse> => {
    try {
        const response: any = await put(ENDPOINTS.updateEndorsementStatus(endorsementId), { ...requestData });
        return response;
    } catch (e) {
        throw e;
    }
};
const renameEndorsementCycleAPI = async (
    endorsementId: string,
    requestData: Record<string, unknown>
): Promise<ISuccessResponse> => {
    try {
        const response: any = await put(ENDPOINTS.renameEndorsementCycle(endorsementId), { ...requestData });
        return response;
    } catch (e) {
        throw e;
    }
};

const grantGenomeAccess = async (emails: string[]): Promise<Record<string, unknown>[]> => {
    const response = await post(ENDPOINTS.scripts + '/giveGenomeAccess', { emails });
    return (response.data as any).rejectedEntries as Record<string, unknown>[];
};

const reassignEndorsementMembersAPI = async (
    endorsementId: string,
    requestData: Record<string, unknown>
): Promise<ISuccessResponse> => {
    try {
        const response: any = await post(ENDPOINTS.reassignEndorsementMembers(endorsementId), { ...requestData });
        return response;
    } catch (e) {
        throw e;
    }
};
const fetchReassignMembersAllowedStatesAPI = async (): Promise<ISuccessResponse> => {
    try {
        const response: any = await get(ENDPOINTS.fetchReassignMembersAllowedStatesAPI());
        return response;
    } catch (e) {
        throw e;
    }
};
const setupHrdUsingGSheet = async (): Promise<ISuccessResponse<IHrMappingSheetsResponse>> => {
    try {
        const response: any = await post(ENDPOINTS.hrdGsheetOperations, {});
        return response;
    } catch (e) {
        throw e;
    }
};

const addPrevalidationToPolicy = async (): Promise<ISuccessResponse> => {
    try {
        const response: any = await post(ENDPOINTS.scripts + '/' + 'updatePrevalidation', {});
        return response;
    } catch (e) {
        throw e;
    }
};

const updatePrevalidationAndAddHrRole = async (): Promise<ISuccessResponse> => {
    try {
        const response: any = await post(ENDPOINTS.scripts + '/' + 'updatePrevalidation', {});
        return response;
    } catch (e) {
        throw e;
    }
};

const invokeSKFOnboardingScript = async (): Promise<ISuccessResponse> => {
    try {
        const response: any = await post(ENDPOINTS.scripts + '/' + 'skfOnboarding', {});
        return response;
    } catch (e) {
        throw e;
    }
};

const addEndorsementNote = async (
    endorsementId: string,
    requestData: Record<string, unknown>
): Promise<ISuccessResponse> => {
    try {
        const response: any = await put(ENDPOINTS.addEndorsementNote(endorsementId), requestData);

        return response;
    } catch (e) {
        throw e;
    }
};
const updateSupportTicketId = async (
    endorsementId: string,
    requestData: Record<string, unknown>
): Promise<ISuccessResponse> => {
    try {
        const response: any = await put(ENDPOINTS.updateSupportTicketId(endorsementId), requestData);

        return response;
    } catch (e) {
        throw e;
    }
};
const fetchECard = async (userId: string, policyId: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await get(ENDPOINTS.getECard(userId, policyId));
        return response;
    } catch (e) {
        throw e;
    }
};

const ledgerExport = async (cdAccountId: string, email: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await post(ENDPOINTS.cdLedgerExport(cdAccountId), { email });
        return response;
    } catch (e) {
        throw e;
    }
};

const assignNickName = async (policyId: string | null, nickName: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await post(ENDPOINTS.assignNickName, { policyId, nickName });
        return response;
    } catch (e) {
        throw e;
    }
};

const deleteEndorsement = async (id: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await del(ENDPOINTS.deleteEndorsement(id));
        return response;
    } catch (e) {
        throw e;
    }
};

const fetchActivePoliciesByCompanyId = async (companyId: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await get(ENDPOINTS.fetchActivePoliciesByCompanyId(companyId));
        return response;
    } catch (e) {
        throw e;
    }
};

const fetchHrmsDataByType = async (type: string, fromDate: string, toDate: string): Promise<ISuccessResponse> => {
    try {
        const response = (await post(ENDPOINTS.fetchHRMSDataByOperationType, { type, fromDate, toDate })) as any;
        return response;
    } catch (e) {
        throw e;
    }
};

const downloadMemberdata = async (insurer: string, endoId: string): Promise<any> => {
    try {
        const response: any = await get(ENDPOINTS.downloadMemberData(insurer, endoId));
        return response.blob();
    } catch (e) {
        throw e;
    }
};

const identifyKyc = async (
    { editUsersData, offBoardUserIds }: Record<string, unknown[]>,
    policyId: string
): Promise<ISuccessResponse> => {
    try {
        const response: any = await post(ENDPOINTS.identifyKyc, { editUsersData, offBoardUserIds, policyId });
        return response;
    } catch (e) {
        throw e;
    }
};

const identifyMidterms = async (
    users: Record<string, unknown>,
    policyId: string,
    companyId: string
): Promise<ISuccessResponse> => {
    try {
        const response: any = await post(ENDPOINTS.identifyMidTerms, { users, policyId, companyId });
        return response;
    } catch (e) {
        throw e;
    }
};

const fetchCompanyDetails = async (companyId: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await get(`${ENDPOINTS.company}/${companyId}`);
        return response;
    } catch (error) {
        throw error;
    }
};

const validateMidterms = async (users: Record<string, unknown>, policyId: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await post(ENDPOINTS.validateMidTerms, { users, policyId });
        return response;
    } catch (e) {
        throw e;
    }
};
const unifiedUploadAPI = async (
    companyId: string,
    policyId: string | undefined,
    enableEnrolmentPortal: boolean,
    enableEndorsements: boolean,
    membersList: {
        ADD: Record<string, unknown>[];
        EDIT: Record<string, unknown>[];
        OFFBOARD: Record<string, unknown>[];
    }
): Promise<ValidatorResult> => {
    try {
        const add: any = (membersList.ADD || []).map((v) => {
            return Object.fromEntries(Object.entries(v).filter(([_, value]) => value !== undefined));
        });
        const edit: any = (membersList.EDIT || []).map((v) => {
            return Object.fromEntries(Object.entries(v).filter(([_, value]) => value !== undefined));
        });
        const offboard: any = (membersList.OFFBOARD || []).map((v) => {
            return Object.fromEntries(Object.entries(v).filter(([_, value]) => value !== undefined));
        });
        const response: any = await post(ENDPOINTS.unifiedUpload, {
            companyId,
            policyId,
            enableEnrolmentPortal,
            enableEndorsements,
            compression: true,
            membersList: compress({
                ADD: add,
                EDIT: edit,
                OFFBOARD: offboard
            })
        });
        let data = response.data.data;
        data = {
            ...data,
            ADD: decompress(data.ADD),
            EDIT: decompress(data.EDIT),
            OFFBOARD: decompress(data.OFFBOARD)
        };
        return data;
    } catch (e) {
        throw e;
    }
};

const recalculatePremiums = async (endorsementId: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await put(ENDPOINTS.recalculatePremiums(endorsementId), {});
        return response;
    } catch (e) {
        throw e;
    }
};

const uploadCDStatement = async (cdAccountId: string, fileName: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await put(ENDPOINTS.uploadCDStatement(cdAccountId), { fileName });
        return response;
    } catch (e) {
        throw e;
    }
};

const downloadCDStatement = async (cdAccountId: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await get(ENDPOINTS.downloadCDStatement(cdAccountId));
        return response;
    } catch (e) {
        throw e;
    }
};

const deleteCDStatement = async (cdAccountId: string, fileIdentifier: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await del(ENDPOINTS.deleteCDStatement(cdAccountId, fileIdentifier));
        return response;
    } catch (e) {
        throw e;
    }
};

const deleteCDAccount = async (cdAccountId: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await del(ENDPOINTS.deleteCDAccount(cdAccountId));
        return response;
    } catch (e) {
        throw e;
    }
};

const uploadPolicyDocument = async (cdAccountId: string, fileName: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await put(ENDPOINTS.uploadPolicyDocument(cdAccountId), { fileName });
        return response;
    } catch (e) {
        throw e;
    }
};

const downloadPolicyDocument = async (cdAccountId: string, fileIdentifier: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await get(ENDPOINTS.downloadPolicyDocument(cdAccountId, fileIdentifier));
        return response;
    } catch (e) {
        throw e;
    }
};

const deletePolicyDocument = async (cdAccountId: string, fileIdentifier: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await del(ENDPOINTS.deletePolicyDocument(cdAccountId, fileIdentifier));
        return response;
    } catch (e) {
        throw e;
    }
};

const downloadHRMappings = async (): Promise<Blob> => {
    try {
        const response: any = await get(ENDPOINTS.hrMappings);
        return response.blob();
    } catch (e) {
        throw e;
    }
};

const fetchEstimatesForIncorrectLives = async (
    policyId: string,
    companyId: string,
    { ADD, EDIT, OFFBOARD }: IMemberDataToUpload
): Promise<IIncorrectPremiumEstimates> => {
    try {
        const formatMissingDate = (date: unknown): string => {
            const dateAsString = date as string;
            if (!Number.isNaN(new Date(dateAsString).getTime())) {
                return new Date(dateAsString).toISOString();
            } else return dateAsString;
        };
        const response: any = await post(ENDPOINTS.incorrectPremiumEsimates, {
            ADD: ADD.length
                ? {
                    membersList: ADD.map((member) => ({
                        employeeId: member.employee_id,
                        name: member.name,
                        relationship: member.relationship_to_account_holders,
                        dob: formatMissingDate(member.date_of_birth),
                        slabId: member.slabId,
                        coverageStartDate: formatMissingDate(member.policy_start_date)
                    })),
                    policyId
                }
                : {},
            EDIT: EDIT.length
                ? {
                    membersList: EDIT.map((member) => ({
                        userId: member.userId,
                        name: member.name,
                        relationship: member.relationship_to_account_holders,
                        dob: formatMissingDate(member.date_of_birth),
                        slabId: member.slabId,
                        coverageStartDate: formatMissingDate(member.policy_start_date)
                    })),
                    policyId
                }
                : {},
            OFFBOARD: OFFBOARD.length
                ? {
                    membersList: OFFBOARD.map((member) => ({
                        userId: member.userId,
                        relationship: member.relationship,
                        coverageEndDate: formatMissingDate(member.dateOfLeaving)
                    }))
                }
                : {},
            companyId
        });
        return response;
    } catch (e) {
        throw e;
    }
};

const fetchCdAccountByPolicyId = async (policyId: string): Promise<ISuccessResponse> => {
    try {
        const response: any = await get(ENDPOINTS.getCdAccountByPolicyId(policyId));
        return response;
    } catch (e) {
        throw e;
    }
};

const addFreshdeskComment = async (ticketId: string, comment: string): Promise<any> => {
    try {
        const response: any = await post(ENDPOINTS.addFreshdeskComment(ticketId), {
            message: comment
        });
        if (response.status !== 200) throw Error('Failed to add comment');
        return response;
    } catch (e) {
        throw e;
    }
};

const addFreshdeskCommentWithAttachment = async (
    ticketId: string,
    comment: string,
    attachment?: Blob,
    fileName?: string
): Promise<any> => {
    const formData = new FormData();
    formData.append('message', comment);
    if (attachment) {
        formData.append('attachment', attachment, fileName);
    }
    try {
        const response: any = await post(ENDPOINTS.addFreshdeskCommentWithAttachment(ticketId), formData);
        if (response.status !== 200) throw Error('Failed to add comment');
        return response;
    } catch (e) {
        throw e;
    }
};

const getMidtermDocument = async (fileURL: string): Promise<ISuccessResponse> => {
    const response: any = await get(ENDPOINTS.getMidtermDocument(fileURL));
    return response;
};

const updateDocumentVerificationStatus = async (
    userId: string,
    endoId: string,
    verification: boolean,
    isMidtermAddition?: boolean
): Promise<ISuccessResponse> => {
    const response: any = await put(
        ENDPOINTS.updateDocumentVerificationStatus(userId, endoId, verification, isMidtermAddition || false)
    );
    return response;
};

const modifyCSDForMidterms = async (
    userId: string,
    policyId: string,
    newCoverageStartDate: string,
    invokedBy: string
): Promise<ISuccessResponse> => {
    const response: any = await put(ENDPOINTS.modifyCSDForMidterms, {
        userId,
        policyId,
        newCoverageStartDate,
        invokedBy
    });
    return response;
};

const createEnrolmentConfig = async (
    companyId: string,
    policyIds: string[],
    joiningCycleStartDate: Date | null,
    joiningCycleEndDate: Date | null,
    enrolmentCycleStartDate: Date | null,
    enrolmentCycleEndDate: Date | null,
    hrEmails: string[],
    csmEmails: string[]
): Promise<ISuccessResponse> => {
    const response: any = await post(ENDPOINTS.enrolmentConfig, {
        enrolmentConfig: {
            companyId: companyId,
            policyIds: policyIds,
            joiningCycleStartDate: joiningCycleStartDate,
            joiningCycleEndDate: joiningCycleEndDate,
            enrolmentStartDate: enrolmentCycleStartDate,
            enrolmentEndDate: enrolmentCycleEndDate,
            recipients: hrEmails,
            carbon_copy: csmEmails
        }
    });
    return response;
};

const fetchUserGroup = (payload: { companyId?: string; userId?: string }): Promise<ISuccessResponse<IUserGroup>> => {
    const searchParams = new URLSearchParams(payload);
    const response: any = get(ENDPOINTS.fetchUserGroup + '?' + searchParams.toString());
    return response;
};

export {
    addCdBalanceAdjustment,
    addEndorsementNote,
    addFreshdeskComment,
    addFreshdeskCommentWithAttachment,
    setupHrdUsingGSheet,
    addPrevalidationToPolicy,
    assignNickName,
    bulkAddMembersAPI,
    bulkEditMembersAPI,
    bulkOffboardUsersAPI,
    createCdAccount,
    createCompanyAPI,
    createEnrolmentConfig,
    createPolicyAPI,
    deleteCDAccount,
    deleteCDStatement,
    deleteEndorsement,
    deletePolicyDocument,
    deleteUserAPI,
    downloadCDStatement,
    downloadHRMappings,
    downloadMemberdata,
    downloadPolicyDocument,
    downloadRaterXlsx,
    fetchActivePoliciesByCompanyId,
    fetchActivePoliciesList,
    fetchAllCompaniesAPI,
    fetchAllEndorsementExceptionsAPI,
    fetchCdAccountByPolicyId,
    fetchCDAccounts,
    fetchCDAccountsByField,
    fetchCdBalanceOverviewById,
    fetchCDLedgerEntriesByID,
    fetchChangeRequestsAPI,
    fetchCompanyDetails,
    fetchCompanyWiseChangeRequestStats,
    fetchCompletedEndorsements,
    fetchECard,
    fetchEndorsementByID,
    fetchEstimatesForIncorrectLives,
    fetchExpiredPoliciesList,
    fetchHrmsDataByType,
    fetchInsurerDetailsAPI,
    fetchPendingEndorsements,
    fetchPolicyAPI,
    fetchReassignMembersAllowedStatesAPI,
    fetchUserPoliciesByUserAPI,
    fetchUsersByCompanyAPI,
    fetchUsersByParamAPI,
    fetchUsersByPolicyAPI,
    freshDeskTicketCreation,
    generateEnrolmentReportAPI,
    getMidtermDocument,
    getRaterAPI,
    getScriptsEndpoint,
    getUserData,
    grantGenomeAccess,
    identifyKyc,
    identifyMidterms,
    invokeSKFOnboardingScript,
    isUserAuthorised,
    ledgerExport,
    migrateDataOfDuplicateUsers,
    modifyCSDForMidterms,
    postBulkMoveCRToNextState,
    reassignEndorsementMembersAPI,
    recalculatePremiums,
    renameEndorsementCycleAPI,
    unifiedUploadAPI,
    updateCdAccount,
    updateDocumentVerificationStatus,
    updateEmployeeIdAPI,
    updateEndorsementExceptionsByIdAPI,
    updateEndorsementStatusAPI,
    updatePolicyAPI,
    updatePrevalidationAndAddHrRole,
    updateSupportTicketId,
    uploadCDStatement,
    uploadCompanyLogoAPI,
    uploadPolicyDocument,
    uploadRaterAPI,
    uploadUHIDsAPI,
    validateMidterms,
    updateUserData,
    fetchUserGroup,
    fetchEnrolmentConfigById,
    extendEnrolmentConfigAPI,
    sendConfirmationEmailAPI,
    fetchEnrolmentConfigByCompanyId
};
