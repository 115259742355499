import React from 'react';
import { Button, PageHeader, Loader } from '../../../../atoms';
import { StyledInputContainer } from '../GlobalMemberSearch/styles';
import { StyledContainer } from './styles';
import { invokeSKFOnboardingScript } from '../../../../../adapters';
import { useToast } from '../../../../hooks/useToast';
// import { isSearchMemberByEmpIdFeatureEnabled } from '../../../../../utils/featureFlags';

const PreValidationAndHrAddition: React.FunctionComponent = () => {
    const toast = useToast();
    const [isPersistanceLoading, setIsPersistanceLoading] = React.useState(false);

    const onSkfOnboardingScriptInvoke = async () => {
        setIsPersistanceLoading(true);
        const result = await invokeSKFOnboardingScript();
        if (result.data.usersOnboarded.length > 0) {
            const employeeIDs: any = [];
            result.data.usersOnboarded.map((user: any) => {
                employeeIDs.push(user.employeeId);
            });
            toast?.displayToast('success', 'Successfully onboarded users', 'Employee Id : ' + employeeIDs.join(', '));
        }
        if (result.data.usersNotOnboarded.length > 0) {
            const employeeIDs: any = [];
            result.data.usersNotOnboarded.map((user: any) => {
                employeeIDs.push(user.employeeId);
            });
            toast?.displayToast(
                'error',
                'User already exist with same employeeId or data format is wrong',
                'Employee Id : ' + employeeIDs.join(',')
            );
        }

        if (result.data.usersOnboarded.length == 0 && result.data.usersNotOnboarded.length == 0) {
            toast?.displayToast('error', 'Please check excel sheet we dont have any data in it', '');
        }
        setIsPersistanceLoading(false);
    };
    const url = process.env.REACT_APP_GS_SKFONBOARDING;
    return (
        <StyledContainer>
            <div>
                <PageHeader>SKF Onboarding script</PageHeader>
            </div>
            <StyledInputContainer>
                {isPersistanceLoading ? (
                    <Loader />
                ) : (
                    <Button type="primary" onClick={onSkfOnboardingScriptInvoke}>
                        Invoke SKF onboarding script
                    </Button>
                )}
            </StyledInputContainer>
            <div>
                (<b>NOTE : </b>Above button will invoke SKF onboarding script which will read data from{' '}
                <a href={url} target="_blank" rel="noreferrer">
                    excel
                </a>{' '}
                and insert into <b>customEnrolmentUser</b> collection)
            </div>
        </StyledContainer>
    );
};

export default PreValidationAndHrAddition;
